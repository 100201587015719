<template>
  <section v-if="!isReady" class="manage-authorization">
    <p-loading />
  </section>
  <section v-else class="manage-authorization">
    <header>
      <h1>Manage Authorization</h1>
    </header>
    <form autocomplete="off" @submit.prevent="submit">
      <p-multiselect
        v-model="registrationNumbers"
        :options="practitioners.map(item => item.registrationNumber)"
        :options-limit="100"
        :multiple="true"
        :taggable="false"
        :get-label-callback="getLabelCallback"
        :disabled="!$hasPermission('users')"
        label="Registration Number(s)"
        placeholder=""
        :searchable="true"
      ></p-multiselect>
      <div v-if="isUpdateAuthorizationRequestFailed" class="error">
        Failed to change permissions for user. Check your input and try again. If an error still occurs, please, contact our developers.
      </div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button v-if="$hasPermission('users')" color="primary" type="submit" :disabled="isUpdateAuthorizationRequestPending">Update</p-button>
      </div>
    </form>

    <footer></footer>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/common/Loading';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'p-loading': Loading,
    'p-button': Button,
    'p-multiselect': Multiselect
  },
  data() {
    return {
      isReady: false,

      registrationNumbers: []
    };
  },
  computed: {
    ...mapState({
      user: s => s.users.item,

      authorization: s => s.portfolio.users.item,

      isUpdateAuthorizationRequestPending: s => s.portfolio.users.isUpdateRequestPending,
      isUpdateAuthorizationRequestFailed: s => s.portfolio.users.isUpdateRequestFailed,

      practitioners: s => s.portfolio.practitioners.collection
    })
  },
  watch: {
    '$route.params': {
      async handler() {
        this.isReady = false;
        await this.$store.dispatch('users/getById', this.$route.params.id);
        await Promise.all([this.$store.dispatch('portfolio/users/getById', this.$store.state.users.item.email)]);

        this.registrationNumbers = this.authorization.registrationNumbers;
        this.isReady = true;
      },
      immediate: true
    }
  },
  async created() {
    await this.$store.dispatch('portfolio/practitioners/getCollection');
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('portfolio/users/update', {
          id: this.user.email,
          registrationNumbers: this.registrationNumbers
        });

        if (!this.isUpdateAuthorizationRequestFailed) {
          this.$router.push({ path: `/people` });
        }
      } catch (e) {
        this.$toast.error({
          title: 'Failed to update user authorization',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    back() {
      this.$router.back();
    },
    getLabelCallback(registrationNumber) {
      const item = this.practitioners.find(item => item.registrationNumber === registrationNumber);
      if (item) {
        return `${item.firstName} ${item.lastName} (${item.registrationNumber})`;
      }

      return registrationNumber;
    }
  }
};
</script>

<style lang="scss" scoped>
.manage-authorization {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr) max-content;
  header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--theme-highlight);
    box-sizing: border-box;

    a {
      font-size: 0.8rem;
    }
  }

  h1 {
    color: var(--theme-on-surface);
    font-size: 1.5rem;
    font-weight: 700;
  }
  form {
    padding: 2rem;
    max-width: 480px;
    overflow-y: scroll;

    > * {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}
</style>
