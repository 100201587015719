<template>
  <p-authorization v-if="$hasPermission('users.read')" />
  <p-not-found v-else />
</template>

<script>
import PortfolioAuthorization from '../components/users/EditAuthorization.vue';
import NotFound from '../components/NotFound.vue';

export default {
  components: {
    'p-authorization': PortfolioAuthorization,
    'p-not-found': NotFound
  }
};
</script>
